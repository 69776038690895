import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import messages from './messages';

export const assetNameValidations = (
  { formatMessage }: IntlShape,
  options: { allowSpaces: boolean; allowStartWithNumber: boolean; allowSpecialCharacters: boolean } = {
    allowSpaces: false,
    allowStartWithNumber: false,
    allowSpecialCharacters: false,
  },
): Yup.StringSchema => {
  const schema = Yup.string().required(formatMessage(messages.errorRequired));
  if (!options.allowSpaces) {
    schema.matches(/^[^\s]+$/, formatMessage(messages.errorSpaces));
  }
  if (!options.allowStartWithNumber) {
    schema.matches(/^[^\d]/, formatMessage(messages.errorStartWithNum));
  }
  if (!options.allowSpecialCharacters) {
    schema.matches(/^[\w-]+$/, formatMessage(messages.errorSpecialCharacters));
  }
  return schema;
};

export function validUuidFormat(str: string): boolean {
  return !!str.match(/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/);
}

export const deviceNameValidations = ({ formatMessage }: IntlShape): Yup.StringSchema =>
  Yup.string()
    .required(formatMessage(messages.errorRequired))
    .matches(/^[^\s]+$/, formatMessage(messages.errorSpaces))
    .matches(/^[\w-]+$/, formatMessage(messages.errorSpecialCharacters));

export const minMaxValidations = ({
  min,
  max,
  units,
  formatMessage,
}: {
  min: number;
  max: number;
  units: string;
  formatMessage: IntlShape['formatMessage'];
}): Yup.NumberSchema =>
  Yup.number()
    .min(min, formatMessage(messages.errorMinimum, { min, units }))
    .max(max, formatMessage(messages.errorMaximum, { max, units }));
