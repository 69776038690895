/**
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
import 'bootstrap/dist/css/bootstrap.css';
import { Router } from 'react-router-dom';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Import all the third party stuff
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'sanitize.css/sanitize.css';

// Import root app
import App from 'containers/App';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';

// Import i18n messages
import { translationMessages } from './i18n';

// Import CSS reset and Global Styles
import { history } from './appHistory';
import store from './appStore';
import { GlobalStyle } from './global-styles';

import * as Sentry from '@sentry/react';
import { appQueryClient } from 'appQueryClient';
import { AntDGlobalStyle } from 'cssOverrides/antdOverrides';
import 'cssOverrides/bootstrap';

const MOUNT_NODE = document.getElementById('app');

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://974411d3fe594a8b9b6a6d14f563e3fc@o541399.ingest.sentry.io/6603743',
  });
}

const render = (messages: typeof translationMessages) => {
  ReactDOM.render(
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <QueryClientProvider client={appQueryClient}>
        <ReactQueryDevtools />
        {/* @ts-ignore - error is Property 'children' does not exist on type but this error only appeared because we updated react's types but not the library's types */}
        <Provider store={store}>
          <LanguageProvider messages={messages}>
            <GlobalStyle />
            <AntDGlobalStyle />
            {/* @ts-ignore - error is Property 'children' does not exist on type but this error only appeared because we updated react's types but not the library's types */}
            <Router history={history}>
              {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore */}
              <App />
            </Router>
          </LanguageProvider>
        </Provider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>,
    MOUNT_NODE,
  );
};

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          'intl/locale-data/jsonp/en.js'
        ),
      ]),
    )
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}
